// a list of tokens by chain
import { ChainId, Currency, Token } from '@nizaglobal/sdk-core'

import { getNativeLogoURI, getTokenLogoURI } from 'lib/hooks/useCurrencyLogoURIs'
import { SafetyLevel } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { CurrencyInfo } from 'uniswap/src/features/dataApi/types'
import {
  BTC_BSC,
  BUSD_BSC,
  DAI,
  DAI_AVALANCHE,
  DAI_BSC,
  ETH_BSC,
  USDC_AVALANCHE,
  USDC_BSC,
  USDC_MAINNET,
  USDC_NIZA,
  USDT,
  USDT_AVALANCHE,
  USDT_BSC,
  USDT_NIZA,
  WBTC,
  WETH_AVALANCHE,
  WRAPPED_NATIVE_CURRENCY,
  nativeOnChain,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: CurrencyInfo[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

function buildCurrencyInfo(commonBase: Currency): CurrencyInfo {
  console.log(WRAPPED_NATIVE_CURRENCY[ChainId.NIZA] as Token)
  console.log(WRAPPED_NATIVE_CURRENCY[ChainId.MAINNET])
  if (!commonBase) {
    // Handle the case where commonBase is undefined or invalid
    console.error('Invalid Currency detected:', commonBase)
    throw new Error('Invalid Currency passed to buildCurrencyInfo')
  }

  const logoUrl = commonBase.isNative
    ? getNativeLogoURI(commonBase.chainId)
    : getTokenLogoURI((commonBase as Token).address, commonBase.chainId)

  return {
    currency: commonBase,
    logoUrl,
    safetyLevel: SafetyLevel.Verified,
    isSpam: false,
  } as CurrencyInfo
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.MAINNET]: [
    nativeOnChain(ChainId.MAINNET),
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
    WRAPPED_NATIVE_CURRENCY[ChainId.MAINNET] as Token,
  ].map(buildCurrencyInfo),
  [ChainId.NIZA]: [
    nativeOnChain(ChainId.NIZA),
    USDC_NIZA,
    USDT_NIZA,
    WRAPPED_NATIVE_CURRENCY[ChainId.NIZA] as Token,
  ].map(buildCurrencyInfo),
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [ChainId.MAINNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.MAINNET], DAI, USDC_MAINNET, USDT, WBTC],
  [ChainId.BNB]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BNB],
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    BTC_BSC,
    BUSD_BSC,
    ETH_BSC,
  ],
  [ChainId.AVALANCHE]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.AVALANCHE],
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE,
  ],
}

export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin'),
    ],
    [USDC_MAINNET, USDT],
    [DAI, USDT],
  ],
}
